// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//= require owl.carousel
import "packs/custom"
require("packs/custom")
// require("packs/wow/wow.min.js")
// import "bootstrap"
window.WOW = require('wowjs').WOW;

$(document).on("turbolinks:load", function() {
  gtag('config', 'G-JY706LR8FV', {'page_location': event.data.url});
})


Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "packs/custom"
require("packs/custom")
